@media print {
  footer,
  nav,
  aecom-header,
  aecom-left-nav {
    display: none;
  }

  .app-body {
    padding-top: 0px !important;
    // height: auto;
    overflow: visible !important;
  }

  .no-print{
    display: none !important;
  }

  .filters-wrapper,
  .header-action,
  .sort::before {
    display: none !important;
  }

  .report-margin {
    margin: 0px !important;
    padding-top: 0px !important;
  }

  .no-print-margin {
    margin: 0px !important;
    width: 612pt !important;
    padding: 0px !important;
    //overflow: hidden !important;
  }
  .app-page {
    overflow: hidden !important;
  }
 
  body {
    zoom: 100%;
    background-color: #000000;
  }

  @page {
    margin: 0;
    size: 612pt 792pt;
    // size: A4 landscape !important;
    // -webkit-print-color-adjust: exact !important; __<<ngThemingMigrationEscapedComment0>>__
    // color-adjust: exact !important; __<<ngThemingMigrationEscapedComment1>>__
  }
}

@page {
  margin: 0;
  size: 612pt 792pt;
  } 

  .app-page {
    overflow: visible !important;
  }

@import "node_modules/@aecom/core/src/assets/styles/core.scss";
@import "./icons.scss";

.content {
  overflow: hidden;
}

body {
  font-family: "urw-din-semi-condensed", "Barlow Semi Condensed", sans-serif;
  background-color: #fff;
}

a {
  text-decoration: none !important;
}

.uppercase {
  text-transform: uppercase;
}

#sidebarwrapper {
  position: fixed;
  z-index: 2;
  left: 0px;
  top: 40px;
  bottom: 0;
  background-color: #111;
}

.app-left-nav .wrapper {
  width: 100%;
  overflow: hidden;
}

.appLogo {
  mask: url("./../assets/img/appLogo.svg") no-repeat 50% 50%;
  background-color: #fff;
  width: 159px;
  height: 16px;
}

.browser .node.root-node,
.nav-browser .children .child-node {
  text-transform: capitalize;
}

.app-left-nav,
.app-header .button.show,
.app-header .button:hover {
  background-color: #111;
}

.form-item {
  margin: 0;
}

.btn-tab {
  width: 100%;
  height: 40px;
  color: #000;

  &.bg-grey-e {
    color: #999;
  }
}

.noItem-body {
  height: 250px;
  margin: auto;
  padding-top: 60px;
}

.noItem-body > div {
  width: 100%;
  text-align: center;
}

.noItem-icon-row {
  width: 60px !important;
  height: 60px !important;
}

.noItem-title-row {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  color: #aaaaaa;
  margin-top: 20px;
}

.noTableFilter .table-container .filters-wrapper {
  display: none;
}

.selectUserTable .table-container {
  background-color: rgba(255, 255, 255, 0) !important;
}

.selectUser_th {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  text-transform: uppercase !important;
  color: #000000 !important;
}

.selectUser_tr_hover {
  line-height: 40px !important;
  overflow: auto !important;
  height: 40px !important;
  border-bottom: 1px solid #eeeeee !important;
}

.selectUser_tr_hover:hover {
  background: rgba(0, 181, 226, 0.1) !important;
}

.selectUser_td_Item,
.assignUser_td_Item {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 17px !important;
  color: #000000 !important;
}

.assignUser_tr_hover {
  line-height: 40px !important;
  overflow: auto !important;
  height: 40px !important;
  border-bottom: 1px solid #eeeeee !important;
}

.header-actions .unchange {
  pointer-events: none;
}

.cusTab .tab-control .tabs .tab.selected {
  color: #000;
  background-color: rgba(0, 181, 226, 0.1);
}

.cusTab .tab-control .tabs {
  border-bottom: 1px solid #00b5e2 !important;
}

.cusTab .tab-control .tabs div.tab {
  min-width: 120px !important;
  border-bottom: none !important;
  color: #999 !important;
  font-weight: 500;
  font-size: 17px;
}

.cusTab .tab-control .tabs .tab:last-child {
  margin-left: auto;
  min-width: 50px !important;
  color: #fff !important;
  background-color: #078283 !important;
}

.cusTab .tab-control .tabs .tab:last-child.selected {
  color: #fff !important;
  background-color: #078283 !important;
}

.cusTab .tab-control .tabs .tab:hover {
  background-color: rgba(0, 181, 226, 0.1) !important;
  color: #000 !important;
}

.itemBlock label {
  font-style: normal;
  font-weight: 500;
  font-size: 17px !important;
  line-height: 20px;
  color: #777;
}

.matchToTab .form-group .header {
  height: 32px;
}

.title-icon-purple {
  background-color: #9b247f;
}

.hideFileList .upload-list {
  display: none;
}

.form-group-header {
  padding: 4px 0;
  height: 32px;
  margin-top: 0 !important;
}

.btn.solid.sm {
  text-transform: none;
  min-width: 62px;
  height: auto;
  font-size: 14px;
  color: #fff;
  padding: 3px 0 !important;
  border-radius: 0;
  line-height: 1;
}

.rfi-status {
  display: flex;
  align-items: center;

  span + div {
    display: flex;
  }

  .btn.sm {
    margin-left: 8px;
    padding: 0;
  }

  .icon {
    margin: 0;
    background-color: #9b247f;
  }

  .icon.disabled {
    background-color: #777;
  }
}

.mat-progress-bar-buffer {
  background-color: #ddd;
}

.mat-progress-bar-fill::after {
  background-color: #9b247f;
}

.bbj-progress-bar {
  --mdc-linear-progress-active-indicator-color: #9b247f; //progress bar color
  --mdc-linear-progress-track-color: #ddd; // background color
}

.table-container table tbody tr > td {
  .sm.btn span {
    color: #fff;
  }
}

.form-item > label,
.form-item > div > label {
  font-weight: 500;
}
//Contract icons
.qn_contract_icon{
  background: url("./../assets/img/report-header-images/queens-icon.svg") no-repeat 50% 50%;
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.bx_contract_icon{
  background: url("./../assets/img/report-header-images/bronx-icon.svg") no-repeat 50% 50%;
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.bk_contract_icon{
  background: url("./../assets/img/report-header-images/brooklyn-icon.svg") no-repeat 50% 50%;
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.mn_contract_icon{
  background: url("./../assets/img/report-header-images/manhattan-icon.svg") no-repeat 50% 50%;
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

//product icon
.submittal_icon{
  background: url("./../assets/img/product-icons/BBJ_product_icon-Submittal.svg") no-repeat 50% 50%;
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.rfi_icon{
  background: url("./../assets/img/product-icons/BBJ_product_icon-RFI.svg") no-repeat 50% 50%;
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.altBottonColor {
  background-color: #ADC6CC;
}
.newReviewBottonColor {
  background-color: rgb(0, 226, 150);
}
.bbj_onboarding_icon {
  background-color: #00b5e2;
  mask: url("../assets/icons/bbj_onboarding_icon.svg") no-repeat 50% 50%;
}

.bbj_product_icon_settings {
  background-color: #BE2150;
  mask: url("../assets/icons/bbj_product_icon_settings.svg") no-repeat 50% 50%;
}
.icon-action_app_support {
  background-color: #00b5e2;
}

.btn.solid:disabled, .btn.solid.disabled {
  background-color: #ddd;
  color: #aaa;
}

.input-checkbox span {
  color: #666;
}

.warningFont {
  color: red;
  font-weight: bold;
}

.cbxReopen .input-checkbox, .rdoReopen .input-radio, .rdoReopen .input-div, .cbxReopen .input-div {
  background-color: #F4F4F4;
  border: 1px solid #DDDDDD;
}

.rdoReopen .input-div, .cbxReopen .input-div {
  width: 100%;
  height: 40px;
  align-items: center;
  display: flex;
}

.rdoReopen .input-div input[type="radio"], .cbxReopen .input-div input[type="checkbox"] {
  margin-left: 12px;
  margin-right: 8px;
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
  transform: scale(1.5);
}

.cbxReopen .input-checkbox {
  padding-left: 12px;
}

.cbxReopen .input-checkbox span, .rdoReopen .input-radio span {
  color: black;
}

.hideHeader .form-group-header {
  display: none;
}
.headerSubTitle .form-group-header {
  display: block;
  height: auto;
}
.mb4 {
  margin-bottom: 4px;
}